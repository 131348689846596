import { z } from 'zod';

import { markSchema } from './common';

export const listReviewEventsPathSchema = z.object({
  id: z.string().uuid(),
  subdomain: z.string(),
});

export const listReviewEventsInputSchema = listReviewEventsPathSchema;

const claimedQuestEventStatus = z.enum(['success', 'fail', 'star', 'flag', 'cancel', 'reward']);

const reviewEventsSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  type: z.union([claimedQuestEventStatus, markSchema]),
  data: z.object({
    message: z.string().optional(),
    bonusXP: z.number().optional(),
  }),
  reviewer: z.object({
    id: z.string().nullable(),
    name: z.string().nullable(),
    avatar: z.string().url().nullish(),
  }),
});

export const listReviewEventsOutputSchema = z.object({
  items: z.array(reviewEventsSchema),
});

export type ListReviewEventsInput = z.infer<typeof listReviewEventsInputSchema>;
export type ListReviewEventsOutput = z.infer<typeof listReviewEventsOutputSchema>;
