import { z } from 'zod';
import { nftTaskSettingsNetworkSchema } from '@zealy/utils';
export const getNFTCollectionMetadataPathSchema = nftTaskSettingsNetworkSchema;
export const getNFTCollectionMetadataQuerySchema = z.object({
    tokenId: z.string().optional(),
});
export const getNFTCollectionMetadataInputSchema = getNFTCollectionMetadataPathSchema;
export const getNFTCollectionMetadataOutputSchema = z.object({
    name: z.string().optional(),
    image: z.string().optional(),
    description: z.string().optional(),
    link: z.string().optional(),
});
